<template>
  <div class="container">
    <div class="apartments__wrapper screen__wrapper">
      <Card v-for="buyer in buyers" :key="buyer.id" :buyer="buyer" @click.native="info(buyer)" />
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="loadObj"></infinite-loading>
  </div>
</template>

<script>
import Card from '../Card'
import Info from '../Info'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'BuyerApartments',
  components: {
    Card,
    InfiniteLoading
  },
  data: () => ({
    isLoading: false
  }),
  computed: {
    buyers() {
      return this.$store.getters['buyers/apartments/apartments']
    },
    hasMoreResults() {
      return this.buyers.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters['buyers/apartments/pagination'].total
    },
    page() {
      return this.$store.getters['buyers/apartments/page']
    }
  },
  methods: {
    info(buyer) {
      this.$modal.display(Info, {buyer}, {name: 'InfoBuyer'})
    },
    async loadObj() {
      this.isLoading = true
      try {
        await this.$store.dispatch('buyers/apartments/fetch')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
